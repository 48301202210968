<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Negociaciones Directas
          </v-col>
          <v-col cols="12" sm="12" md="6" class="d-none">
            <v-btn
              color=""
              dark
              class="mb-2 float-right blue-grey darken-2"
              @click="modalNuevo"
            >
              Visualizar negociación
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">

        <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
                Negociaciones pendientes de aprobación
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
                Todas las negociaciones
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

        </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    class="elevation-0"
                    :headers="headers"
                    :items="items"
                    :loading="tableLoading"
                    :search="filtro"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}'
                    }"
                  >
                    <template v-slot:top>
                      <!-- v-container, v-col and v-row are just for decoration purposes. -->
                      <v-container fluid>
                        <v-row>
                          <v-col cols="6" md="6" sm="6" xs="6">
                            <v-text-field
                              autocomplete="off"
                              class="required"
                              dense
                              filled
                              color="blue-grey lighten-2"
                              v-model="filtro"
                              label="Descripción de la negociación, id"
                              maxlength="200"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" md="6" sm="6" xs="6">
                            <v-select
                              v-model="estado"
                              :items="estados"
                              :loading="false"
                              dense
                              filled
                              label="Estado"
                              :no-data-text="'No existen estados registrados'"
                              menu-props="auto"
                              return-object
                            ></v-select>
                          </v-col>

                          <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                            <v-btn
                              class="ma-0 white--text d-none"
                              medium
                              color="light-blue darken-2"
                            >
                              <v-icon left>mdi-magnify</v-icon> Buscar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>

                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.id }}</td>
                        <td>{{ item.institucion }}</td>
                        <td>{{ item.titulo }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td>{{ item.modalidad }}</td>
                        <td>{{ item.fechaAprobacion }}</td>
                        <td>
                          <v-chip
                            class="ma-2 font-weight-medium"
                            label
                            :color="
                              item.estadoId === 1
                                ? 'blue-grey lighten-4'
                                : item.estadoId === 2
                                ? 'blue lighten-4'
                                : item.estadoId === 2
                                ? 'green lighten-4'
                                : item.estadoId === 6
                                ? 'cyan lighten-4'
                                : item.estadoId === 7
                                ? 'deep-orange lighten-4'
                                : item.estadoId === 8
                                ? 'pink lighten-4'
                                : item.estadoId === 9
                                ? 'deep-orange lighten-4'
                                : 'pink lighten-4'
                            "
                            :text-color="
                              item.estadoId === 1
                                ? 'blue-grey lighten-1'
                                : item.estadoId === 2
                                ? 'blue lighten-1'
                                : item.estadoId === 3
                                ? 'green lighten-1'
                                : item.estadoId === 6
                                ? 'cyan darken-1'
                                : item.estadoId === 7
                                ? 'deep-orange lighten-1'
                                : item.estadoId === 8
                                ? 'pink lighten-1'
                                : item.estadoId === 9
                                ? 'deep-orange lighten-1'
                                : 'pink darken-1'
                            "
                            small
                          >
                            {{ item.estado }}
                          </v-chip>
                        </td>
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="obtenerDatosItem(item.id)"
                          >
                            <v-icon left>mdi-eye</v-icon> Visualizar
                          </v-btn>

                          <v-btn
                            v-if="item.estadoId === 2"
                            class="ma-2 btn-bg-light teal--text lighten-1--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="teal lighten-4"
                            @click="mostrarDialogAprobacion(item.id, item.descripcion)"
                          >
                            <v-icon left>mdi-check-all</v-icon> Aprobar
                          </v-btn>

                          <v-btn v-if="item.estadoId === 2"
                            class="ma-2 btn-bg-light red--text darken-1--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="red lighten-4"
                            @click="mostrarDialogRechazo(item.id, item.descripcion)"
                          >
                            <v-icon left>mdi-close</v-icon> Rechazar
                          </v-btn>

                          <v-btn v-if="item.estadoId === 2"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="mostrarDialogCorreccion(item.id, item.descripcion)"
                          >
                            <v-icon left>mdi-playlist-edit</v-icon> Enviar corrección
                          </v-btn>

                          <v-btn
                          v-if="item.estadoId === 3 || item.estadoId === 4 || item.estadoId === 5 || item.estadoId === 6 "
                          class="
                              ma-2
                              btn-bg-light
                              blue-grey--text
                              lighten-2--text
                              font-weight-medium
                              text-capitalize
                            "
                          small
                          depressed
                          color="blue-grey lighten-5"
                          :href="
                            `https://rpts.segeplan.gob.gt:8080/jasperserver/rest_v2/reports/reports/SICOOPERA/Resumen.pdf?NegociacionId=${item.id}&j_password=reporte&j_username=reportes`
                          "
                          target="_blank"
                        >
                          <v-icon left>mdi-file-pdf-box</v-icon> Descargar Reporte
                        </v-btn>

                          <v-menu v-if="item.estadoId != 3"
                            v-model="menuHistorial[item.id]"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            :close-on-content-click="false"
                            :nudge-width="300"
                            offset-x
                          >
                            <template v-slot:activator="{ on, attrsMenu }">
                              <v-btn
                                class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                color="blue-grey lighten-5"
                                dark
                                small
                                depressed
                                v-bind="attrsMenu"
                                v-on="on"
                                @click="mostrarHistorialNegociacion(item.id)"
                              >
                                <v-icon left>mdi-eye</v-icon> Historial de revisiones
                              </v-btn>
                            </template>

                            <v-card class="elevation-0">
                              <div v-if="skeletonHistorial[item.id]">
                                <v-row>
                                    <v-col cols="12" md="12">
                                          <v-skeleton-loader
                                          v-bind="attrs"
                                          min-height="200px"
                                          type="article, article"
                                          >
                                          </v-skeleton-loader>
                                  </v-col>
                                </v-row>
                              </div>

                              <div v-if="!skeletonHistorial[item.id]">
                                <v-list subheader>
                                  <v-subheader class="pl-4">Historial</v-subheader>

                                  <v-list-item v-for="(item, i) in datosHistorial" :key="i" style="height: 25px; margin-top: 5px;">
                                    <v-list-item-content>
                                      <v-list-item-title>{{item.estadoNegociacion}} | {{item.fechaCreacion}}</v-list-item-title>
                                      <v-list-item-subtitle v-if="item.comentarios"><div v-html="addBr(item.comentarios)"></div></v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              

                              </div>
                            </v-card>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>


            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    class="elevation-0"
                    :headers="headers"
                    :items="negociacionesRegistradas"
                    :loading="tableLoading"
                    :search="filtro"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}'
                    }"
                  >
                    <template v-slot:top>
                      <!-- v-container, v-col and v-row are just for decoration purposes. -->
                      <v-container fluid>
                        <v-row>
                          <v-col cols="6" md="6" sm="6" xs="6">
                            <v-text-field
                              autocomplete="off"
                              class="required"
                              dense
                              filled
                              color="blue-grey lighten-2"
                              v-model="filtro"
                              label="Descripción de la negociación, id"
                              maxlength="200"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" md="6" sm="6" xs="6">
                            <v-select
                              v-model="estado"
                              :items="estados"
                              :loading="false"
                              dense
                              filled
                              label="Estado"
                              :no-data-text="'No existen estados registrados'"
                              menu-props="auto"
                              return-object
                            ></v-select>
                          </v-col>

                          <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                            <v-btn
                              class="ma-0 white--text d-none"
                              medium
                              color="light-blue darken-2"
                            >
                              <v-icon left>mdi-magnify</v-icon> Buscar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>

                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.id }}</td>
                        <td>{{ item.institucion }}</td>
                        <td>{{ item.titulo }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td>{{ item.modalidad }}</td>
                        <td>{{ item.fechaAprobacion }}</td>
                        <td>
                          <v-chip
                            class="ma-2 font-weight-medium"
                            label
                            :color="
                              item.estadoId === 1
                                ? 'blue-grey lighten-4'
                                : item.estadoId === 2
                                ? 'blue lighten-4'
                                : item.estadoId === 3
                                ? 'green lighten-4'
                                : item.estadoId === 6
                                ? 'cyan lighten-4'
                                : item.estadoId === 7
                                ? 'deep-orange lighten-4'
                                : item.estadoId === 8
                                ? 'pink lighten-4'
                                : item.estadoId === 9
                                ? 'deep-orange lighten-4'
                                : 'pink lighten-4'
                            "
                            :text-color="
                              item.estadoId === 1
                                ? 'blue-grey lighten-1'
                                : item.estadoId === 2
                                ? 'blue lighten-1'
                                : item.estadoId === 3
                                ? 'green lighten-1'
                                : item.estadoId === 6
                                ? 'cyan darken-1'
                                : item.estadoId === 7
                                ? 'deep-orange lighten-1'
                                : item.estadoId === 8
                                ? 'pink lighten-1'
                                : item.estadoId === 9
                                ? 'deep-orange lighten-1'
                                : 'pink darken-1'
                            "
                            small
                          >
                            {{ item.estado }}
                          </v-chip>
                        </td>
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="obtenerDatosItem(item.id)"
                          >
                            <v-icon left>mdi-eye</v-icon> Visualizar
                          </v-btn>

                          <!-- <v-btn
                            v-if="item.estadoId === 2"
                            class="ma-2 btn-bg-light teal--text lighten-1--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="teal lighten-4"
                            @click="mostrarDialogAprobacion(item.id, item.descripcion)"
                          >
                            <v-icon left>mdi-check-all</v-icon> Aprobar
                          </v-btn>

                          <v-btn v-if="item.estadoId === 2"
                            class="ma-2 btn-bg-light red--text darken-1--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="red lighten-4"
                            @click="mostrarDialogRechazo(item.id, item.descripcion)"
                          >
                            <v-icon left>mdi-close</v-icon> Rechazar
                          </v-btn>

                          <v-btn v-if="item.estadoId === 2"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="mostrarDialogCorreccion(item.id, item.descripcion)"
                          >
                            <v-icon left>mdi-playlist-edit</v-icon> Enviar corrección
                          </v-btn> -->
                          <!-- <v-menu v-if="item.estadoId != 3"
                            v-model="menuHistorial[item.id]"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            :close-on-content-click="false"
                            :nudge-width="300"
                            offset-x
                          >
                            <template v-slot:activator="{ on, attrsMenu }">
                              <v-btn
                                class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                color="blue-grey lighten-5"
                                dark
                                small
                                depressed
                                v-bind="attrsMenu"
                                v-on="on"
                                @click="mostrarHistorialNegociacion(item.id)"
                              >
                                <v-icon left>mdi-eye</v-icon> Historial de revisiones
                              </v-btn>
                            </template>

                            <v-card class="elevation-0">
                              <div v-if="skeletonHistorial[item.id]">
                                <v-row>
                                    <v-col cols="12" md="12">
                                          <v-skeleton-loader
                                          v-bind="attrs"
                                          min-height="200px"
                                          type="article, article"
                                          >
                                          </v-skeleton-loader>
                                  </v-col>
                                </v-row>
                              </div>

                              <div v-if="!skeletonHistorial[item.id]">
                                <v-list subheader>
                                  <v-subheader class="pl-4">Historial</v-subheader>

                                  <v-list-item v-for="(item, i) in datosHistorial" :key="i" style="height: 25px; margin-top: 5px;">
                                    <v-list-item-content>
                                      <v-list-item-title>{{item.estadoNegociacion}} | {{item.fechaCreacion}}</v-list-item-title>
                                      <v-list-item-subtitle v-if="item.comentarios"><div v-html="addBr(item.comentarios)"></div></v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              

                              </div>
                            </v-card>
                          </v-menu> -->
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>





          </v-tabs-items>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: dialog aprobacion -->
    <v-dialog v-model="dialogAprobacion" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">
          Aprobar negociación

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="dialogAprobacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form  ref="formAprobacion" v-on:submit.prevent="aprobarNegociacion(1)" v-model="validFormAprobacion">
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                Está seguro de aprobar la negociación seleccionada?
              </p>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                     autocomplete="off"
                     class="required"
                     dense
                     filled
                     rows="4"
                     color="blue-grey lighten-2"
                     v-model="comentariosAprobacion"
                     label="Agregue sus comentarios"
                     :rules="[
                       required('comentarios'),
                        minLength('comentarios', 10),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                  
                  </v-textarea>
                  <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12">
                <v-btn
                color="blue-grey darken-2"
                text
                @click="dialogAprobacion = false"
                :disabled="btnAprobacionLoading"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="teal--text lighten-1--text"
                text
                type="submit"
                :loading="btnAprobacionLoading"
                :disabled="!validFormAprobacion"
              >
                <v-icon left>mdi-check-all</v-icon>
                Sí, aprobar
              </v-btn>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>

      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog suspension -->
    <v-dialog v-model="dialogRechazo" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">
          Rechazar negociación

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="dialogRechazo = false; comentarios=''"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form  ref="formRechazo" v-on:submit.prevent="aprobarNegociacion(2)" v-model="validFormRechazo">
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                Está seguro de rechazar la negociación seleccionada?
              </p>
              <v-row>
                <v-col cols="12">

                  <v-textarea
                     autocomplete="off"
                     class="required"
                     dense
                     filled
                     rows="4"
                     color="blue-grey lighten-2"
                     v-model="comentariosRechazo"
                     label="Agregue sus comentarios"
                     :rules="[
                       required('comentarios'),
                        minLength('comentarios', 10),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                  
                  </v-textarea>
                  <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
                <v-btn
                color="blue-grey darken-2"
                text
                @click="dialogRechazo = false"
                :disabled="btnAprobacionLoading"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="red--text darken-1--text"
                text
                type="submit"
                :loading="btnAprobacionLoading"
                :disabled="!validFormRechazo"
              >
                <v-icon>mdi-close</v-icon>
                Sí, rechazar
              </v-btn>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>

        

          
        
      </v-card>
    </v-dialog>
    <!--end: dialog rechazo-->



    <!--begin:: dialog correccion -->
    <v-dialog v-model="dialogCorreccion" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">
          Corrección de negociación

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="dialogCorreccion = false; comentarios=''"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form  ref="formAprobacion" v-on:submit.prevent="aprobarNegociacion(3)" v-model="validFormCorreccion">
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                Ingrese sus comentarios de corrección/correcciones 
              </p>
              <v-row>
                <v-col cols="12">

                  <v-textarea
                     autocomplete="off"
                     class="required"
                     dense
                     filled
                     rows="4"
                     color="blue-grey lighten-2"
                     v-model="comentariosCorreccion"
                     label="Agregue sus comentarios"
                     :rules="[
                       required('comentarios'),
                        minLength('comentarios', 10),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                  
                  </v-textarea>
                  <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                </v-col>
              </v-row>
            </v-col>
          </v-row>

           <v-row>
            <v-col cols="12" md="12" sm="12">
                <v-btn
                  color="green--text darken-1--text"
                  text
                  class="float-right"
                  type="submit"
                  :loading="btnAprobacionLoading"
                  :disabled="!validFormCorreccion"
                >
                  <v-icon>mdi-check-all</v-icon>
                    Enviar Corrección
                </v-btn>

                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  @click="dialogCorreccion = false"
                  :disabled="btnAprobacionLoading"
                >
                  Cancelar
                </v-btn>
              
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
       
      </v-card>
    </v-dialog>
    <!--end: dialog correcciones-->

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="900"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.modalidadId"
                  :items="modalidades"
                  :loading="ddModalidadesLoading"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Modalidad"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('Modalidad')]"
                  menu-props="auto"
                  :disabled="true"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo -->
              <!-- <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.paisId"
                  :items="paises"
                  :loading="ddPaisesLoading"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Pais"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('País')]"
                  menu-props="auto"
                  :disabled="true"
                >
                </v-select>
              </v-col> -->
              <!--fin:: campo-->
            </v-row>

            <v-row>

              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.mecanismo"
                  label="Mecanismo"
                  :rules="[
                    required('Mecanismo'),
                    minLength('Mecanismo', 5),
                    maxLength('Mecanismo', 200)
                  ]"
                  maxlength="200"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosItem.fechaAprobacion"
                  label="Fecha de aprobación"
                  :rules="[
                    required('Fecha de aprobación'),
                    minLength('Fecha de aprobación', 10),
                    dateFormat('Fecha de aprobación')
                  ]"
                  maxlength="10"
                  v-mask="'##/##/####'"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosItem.iniciaVigencia"
                  label="Fecha inicio de vigencia"
                  :rules="[
                    required('Fecha inicio de vigencia'),
                    minLength('Fecha inicio de vigencia', 10),
                    dateFormat('Fecha inicio de vigencia')
                  ]"
                  maxlength="10"
                  v-mask="'##/##/####'"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosItem.finalizaVigencia"
                  label="Fecha finalización de vigencia"
                  :rules="[
                    required('Fecha finalización de vigencia'),
                    minLength('Fecha finalización de vigencia', 10),
                    dateFormat('Fecha finalización de vigencia')
                  ]"
                  maxlength="10"
                  v-mask="'##/##/####'"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->
              
              <v-col cols="12">
                 <v-btn
                  class="white--text mb-2 float-right"
                  color="light-blue-502"
                  dark
                  @click="descargarArchivo(datosItem.pathDocumentoFormalizacion)"
                >
                  <v-icon left>mdi-download</v-icon> Descargar documento de respaldo
                </v-btn>
              </v-col>
              <!--inicio:: campo 
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <div v-if="archivoExiste">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="pt-1 pb-0 mb-0 col-btn"
                    >
                      <label class="grey--text body-2 mb-0"
                        >Documento respaldo</label
                      >
                    </v-col>
                    <v-col cols="12" md="12" sm="12" xs="12" class="pt-0">
                      <v-btn
                        class="ma-2 mt-0 mb-0 ml-0"
                        medium
                        tile
                        outlined
                        color="indigo"
                        @click="
                          descargarArchivo(
                            `${baseIntUrl}${datosUsuario.pathDocumentoRespaldo}`
                          )
                        "
                      >
                        <v-icon left>mdi-cloud-download</v-icon> Descargar
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <template>
                  <v-file-input
                    id="mecanismoFile"
                    dense
                    filled
                    placeholder="Seleccionar documento"
                    label="Documento respaldo mecanismo"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[fileRequired('Documento respaldo mecanismo')]"
                    :show-size="1000"
                    ref="mecanismoFile"
                    class="required"
                    :disabled="true"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                text-overline
                                grey--text
                                text--darken-3
                                mx-2
                                "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
              fin:: campo-->

              <!--inicio:: campo -->
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  rows="4"
                  color="blue-grey lighten-2"
                  v-model="datosItem.descripcion"
                  label="Descripción"
                  :rules="[
                    required('Descripción'),
                    minLength('Descripción', 5),
                    maxLength('Descripción', 200)
                  ]"
                  maxlength="200"
                  :disabled="true"
                ></v-textarea>
              </v-col>
              <!--fin:: campo-->
            </v-row>
          </v-form>


          <v-card outlined class="mt-4">
            <v-card-text>

              <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                      <v-subheader class="text-h5 text-uppercase font-weight-black black--text">
                        Roles y actores
                      </v-subheader>
                  </v-col>

              </v-row>
              <v-row>

                  <v-col cols="12" md="12" sm="12">
                    <template>
                      <v-data-table
                        :headers="headersActores"
                        :items="rolesActores"
                        :items-per-page="5"
                        :loading="tableRolesActoresLoading"
                        class="elevation-1"
                      >
                        <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.nombre }}</td>
                          <td>{{ item.pais }}</td>
                          <td>{{ item.agencia }}</td>
                          <td>{{ item.nombreFuenteMultilateral }}</td>
                          

                          <!--<td>
                            <v-btn
                              class=" ma-2
                              btn-bg-light
                              blue-grey--text
                              lighten-2--text
                              font-weight-medium
                              text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              :disabled="btnEliminarRolActorDisabled"
                              @click="eliminarRolActor(item.id)"
                            >
                              <v-icon left>mdi-delete</v-icon> Eliminar
                            </v-btn>

                            <v-btn v-if="item.estadoId===1"
                              class=" ma-2
                              btn-bg-light
                              blue-grey--text
                              lighten-2--text
                              font-weight-medium
                              text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="mostrarFormularioProductos(item.id)"
                            >
                              <v-icon left>mdi-plus</v-icon> Agregar Productos
                            </v-btn>
                          </td>-->
                        </tr>
                      </template>
                      </v-data-table>
                    </template>
                    </v-col>
                </v-row>
            </v-card-text>

          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            :disabled="btnRegistroLoading"
          >
            Cerrar
          </v-btn>
          <!--:disabled="!validDocForm" type="submit"-->
          <!-- <v-btn
            color="teal--text lighten-1--text"
            text
            class="mb-2"
            @click="
              mostrarDialogAprobacion(datosItem.id, datosItem.descripcion)
            "
            :loading="btnAprobacionLoading"
          >
            <v-icon left>mdi-check-all</v-icon>
            Aprobar
          </v-btn>

          <v-btn
            color="red--text darken-1--text"
            text
            class="mb-2"
            @click="mostrarDialogRechazo(datosItem.id, datosItem.descripcion)"
            :loading="btnAprobacionLoading"
          >
            <v-icon>mdi-close</v-icon>
            Rechazar
          </v-btn> -->
        </v-card-actions>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <!--end:: modal registro-->


    <!--inicio:: visualizacion de la negociacion -->
    <template>
      <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="`negociacion-${datosItem.id}`"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
        <!--@progress="onProgress($event)"-->
            <section slot="pdf-content" class="pdf-content">
                <!-- PDF Content Here -->
                <div class="row mt-6">
                  <div class="col col-sm-12 col-md-12">
                    <p class="text-h6 text-uppercase text-center font-weight-medium">Datos de la negociación</p>
                    <hr/>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col col-md-4 col-xs-12">
                      <v-list-item two-line>
                          <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h5">Modalidad</v-list-item-title>
                              <v-list-item-subtitle class="text-h5">{{datosItem.modalidad}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </div>

                  <div class="col col-md-4 col-xs-12">
                      <v-list-item two-line>
                          <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h5">País</v-list-item-title>
                              <v-list-item-subtitle class="text-h5">{{datosItem.pais}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </div>

                  <div class="col col-md-4 col-xs-12">
                      <v-list-item two-line>
                          <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6">Mecanismo de ejecución</v-list-item-title>
                              <v-list-item-subtitle class="text-h6">{{datosItem.mecanismo}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-md-4 col-xs-12">
                      <v-list-item two-line>
                          <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6">Fecha de aprobación</v-list-item-title>
                              <v-list-item-subtitle class="text-h6">{{datosItem.fechaAprobacion}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </div>

                  <div class="col col-md-4 col-xs-12">
                      <v-list-item two-line>
                          <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6">Inicio de la vigencia</v-list-item-title>
                              <v-list-item-subtitle class="text-h6">{{datosItem.iniciaVigencia}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </div>

                  <div class="col col-md-4 col-xs-12">
                      <v-list-item two-line>
                          <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6">Finalización de la vigencia</v-list-item-title>
                              <v-list-item-subtitle class="text-h6">{{datosItem.finalizaVigencia}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-md-12 col-xs-12">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Descripción</v-list-item-title>
                                <v-list-item-subtitle class="text-h6">{{datosItem.descripcion}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                  </div>
            </section>
        </vue-html2pdf>
      </div>
    </template>
    <!--fin:: visualizacion de la negociacion-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";
import VueHtml2pdf from 'vue-html2pdf'
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DownloadFile from "@/core/untils/downloadfile.js";
import validations from "@/core/untils/validations.js";

import { OBTENER_MODALIDADES } from "@/core/services/store/catalogos/modalidadesnegociaciondirecta/modalidadnegociaciondirecta.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";
import { OBTENER_ROLES_ACTORES_CSS } from "@/core/services/store/negociaciones/iniciativas/rolesactores/asignacionrolactor.module";
import {
  OBTENER_NEGOCIACIONES,
  REGISTRAR_NEGOCIACION_DIRECTA,
  OBTENER_NEGOCIACION,
  ACTUALIZAR_NEGOCIACION,
  ENVIAR_NEGOCIACION
} from "@/core/services/store/negociaciones/negociaciondirecta.module";

export default {
  name: "AdminNegociacionDirectaDad",
  data() {
    return {
      tab: 'tab-1',
      tableLoading:false,
      validForm: false,
      validFormAprobacion: false,
      validFormRechazo: false,
      validFormCorreccion: false,
      ddRolesLoading: false,
      dialogRegistro: false,
      dialogLoaderVisible: false,
      dialogAprobacion: false,
      dialogRechazo: false,
      dialogCorreccion: false,
      dialogLoaderText: "",
      switchItemEstado: true,
      filtro: "",
      dialog: false,
      modalTitle: "Visualizar negociación",
      accion: 1,
      ddModalidadesLoading: false,
      ddPaisesLoading: false,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      btnAprobacionLoading: false,
      documentoMecanismo: [],
      archivoExiste: false,
      negociacionesDirectas: [],
      modalidades: [],
      paises: [],
      items: [],
      negociacionesRegistradas:[],
      datosItem: {
        id: 0,
        modalidadesId: 0,
        paisesId: 0,
        mecanismo: "",
        pathDocumentoMecanismo: "",
        fechaAprobacion: "",
        fechaInicioVigencia: "",
        fechaFinVigencia: "",
        descripcion: "",
        estadosId: 1
      },
      comentariosCorreccion: "",
      comentariosAprobacion: "",
      comentariosRechazo: "",
      estado: "",
      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Registrada" },
        { value: 2, text: "En Verificación" },
        { value: 3, text: "Aprobada" },
        { value: 4, text: "En Ejecución" },
        { value: 5, text: "Finalizada" },
        { value: 6, text: "Cancelada" },
        { value: 8, text: "Rechazada" },
        { value: 9, text: "Corrección" }
      ],
      rolesActores: [],
      tableRolesActoresLoading: false,
      menuHistorial: [],
      datosHistorial:[],
      skeletonHistorial: [],
      ...validations
    };
  },
  components: {
    DialogLoader,
    SnackAlert,
    VueHtml2pdf
  },
  methods: {
    //Obtener paises
    async obtenerPaises() {
      this.paises = [];
      this.ddPaisesLoading = true;

      this.$store
        .dispatch(OBTENER_PAISES)
        .then(() => {
          this.paises = this.$store.state.pais.paises;
        //  console.log(this.paises);
          //console.log(this.items)
          this.ddPaisesLoading = false;
        })
        .catch(() => {
          this.ddPaisesLoading = false;
        });
    },

    //Obtener modalidades
    async obtenerModalidades() {
      this.modalidades = [];
      this.ddModalidadesLoading = true;

      this.$store
        .dispatch(OBTENER_MODALIDADES)
        .then(() => {
          this.modalidades = this.$store.state.modalidadnegociaciondirecta.modalidades;
          //console.log(this.modalidades);
          //console.log(this.items)
          this.ddModalidadesLoading = false;
        })
        .catch(() => {
          this.ddModalidadesLoading = false;
        });
    },

    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estado.text;
    },

    modalNuevo() {
      this.accion = 1;
      this.modalTitle = "Visualizar negociación";
      this.btnRegistroText = "Registrar Negociación";
      this.switchItemEstado = true;
      this.archivoExiste = false;
      this.dialogRegistro = true;
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
    },

    //Mostrar el dialogo para aprobacion
    mostrarDialogAprobacion(id, descripcion) {
      this.datosItem.descripcion = descripcion;
      this.datosItem.id = id;
      this.dialogAprobacion = true;
    },

    //Mostrar el dialogo para rechazo
    mostrarDialogRechazo(id, descripcion) {
      this.datosItem.descripcion = descripcion;
      this.datosItem.id = id;
      this.dialogRechazo = true;
    },

    //Mostrar el dialogo para enviar correcciones
    mostrarDialogCorreccion(id, descripcion){
      this.datosItem.descripcion = descripcion;
      this.datosItem.id = id;
      this.dialogCorreccion = true;
    },

    //Aprobar o rechazar negociacion
    async aprobarNegociacion(tipo) {
     
      let datos = {};
      this.btnAprobacionLoading = true;
     
      
      
      if(tipo===1){
        datos.estadoNegociacionId = 3;
        datos.comentarios = this.comentariosAprobacion;
      } else if(tipo==2){
        datos.estadoNegociacionId = 8;
        datos.comentarios = this.comentariosRechazo;
      } else if(tipo===3){
        datos.estadoNegociacionId = 9;
        datos.comentarios = this.comentariosCorreccion;
      }

      datos.negociacionId = this.datosItem.id;
      datos.id = this.datosItem.id;

      this.$store
        .dispatch(ENVIAR_NEGOCIACION, datos)
        .then(res => {
          this.btnAprobacionLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow("success", "Mensaje",res.message);
            this.obtenerItems();
            this.obtenerNegociacionesRegistradas();

            if(tipo===1){
              this.dialogAprobacion = false;
                this.comentariosAprobacion = "";
            } else if(tipo==2){
              this.dialogRechazo = false;
              this.comentariosRechazo = "";
            } else if(tipo===3){
              this.dialogCorreccion = false,
              this.comentariosCorreccion = "";

            }

              
          } else {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
          }
          this.btnAprobacionLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnAprobacionLoading = false;
        });
    },

    resetForm() {
      this.datosItem = {
        id: 0,
        modalidadesId: 0,
        paisesId: 0,
        mecanismo: "",
        pathDocumentoMecanismo: "",
        fechaAprobacion: "",
        fechaInicioVigencia: "",
        fechaFinVigencia: "",
        descripcion: "",
        estadosId: 1
      };
      this.archivoExiste = false;
      if (this.documentoMecanismo && this.documentoMecanismo.size) {
        //console.log("aui")
        //document.querySelector('#respaldoFile').value = '';
        //this.$refs.respaldoFile.reset();
        this.documentoMecanismo = [];
        this.$refs.mecanismoFile.reset();
        //this.$refs['respaldoFile'][0].reset();
      }
      this.documentoMecanismo = [];

      this.switchItemEstado = true;
      this.accion = 1;
    },

    /// Obtener los items de negociaciones
    async obtenerItems() {
      this.items = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_NEGOCIACIONES, {estado: 2, tipo: 1})
        .then(res => {
          if(res.status ==200){
            this.items = res.data;
          }
          //console.log(this.items);
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },



    /// Obtener los items de negociaciones
    async obtenerNegociacionesRegistradas() {
      this.negociacionesRegistradas = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_NEGOCIACIONES, {estado: 0, tipo: 1})
        .then(res => {
          if(res.status ==200){
            this.negociacionesRegistradas = res.data;
          }
          //console.log(this.items);
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    ///Registro de usuario
    registrarItem() {
      this.btnRegistroLoading = true;

      //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
      this.switchItemEstado
        ? (this.datosItem.estadosId = 1)
        : (this.datosItem.estadosId = 2);

      //?Si accion es 1 se realiza el registro
      if (this.accion === 1) {
        this.$store
          .dispatch(REGISTRAR_NEGOCIACION_DIRECTA, this.datosItem)
          .then(res => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.obtenerItems();
              this.dialogRegistro = false;
              this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
      }
      //?Si accion es 2 se realiza la actualizacion
      else {
        this.$store
          .dispatch(ACTUALIZAR_NEGOCIACION, {
            datosItem: this.datosItem,
            id: this.datosItem.id
          })
          .then(res => {
            this.btnLoading = false;
            this.dialogLoaderVisible = false;
            this.btnRegistroLoading = false;

            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.dialog = false;
              this.obtenerItems();
              this.obtenerNegociacionesRegistradas();
              this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
      }
    },

    //!Obtener datos de un item especifico
    async obtenerDatosItem(Id) {
      this.rolesActores=[];
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";
      this.empresaId = Id;
      //this.dialogLoaderVisible = true;
      //this.dialogLoaderText = "Obteniendo información...";
      this.dialogTitulo = `Actualizar ${this.itemName}`;
      this.btnRegistroText = `Actualizar ${this.itemName}`;
      //!Pre seleccionar el tab de datos de la empresa
      this.activeTab = "empresa";
      await this.$store
        .dispatch(OBTENER_NEGOCIACION, Id)
        .then(res => {
          //console.log(res);
          if (res.status === 200) {
            this.datosItem = this.$store.state.negociacionDirecta.negociacionDirecta;
            this.datosItem.estadosId === 1
              ? (this.switchItemEstado = true)
              : (this.switchItemEstado = false);
            this.accion = 2;
            this.dialogLoaderVisible = false;
            this.obtenerRolesActores(Id);
            this.dialogRegistro = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
          this.dialogLoaderVisible = false;
        });
    },



    
    //Obtener las asignaciones de roles y actores de la iniciativa
    async obtenerRolesActores(idNegociacion){
       this.rolesActores = [];
       this.tableRolesActoresLoading = true;
     
      await this.$store
        .dispatch(OBTENER_ROLES_ACTORES_CSS, {id: idNegociacion})
        .then(res => {
        //  console.log(res)
          if(res.status===200){
            this.rolesActores = res.data;
            //this.datosItem.area === "Rural"  ? this.areaSeleccionada = {id: 1, nombreArea: this.datosItem.area}  : this.areaSeleccionada = {id: 2, nombreArea: this.datosItem.area};
          }
            this.tableRolesActoresLoading = false;
        })
        .catch(() => {
          this.tableRolesActoresLoading = false;
        });

      
    },



    visualizarPoa(id){
      this.progress = 0;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";
     

      this.obtenerDatosItem(id).then(()=>{
        this.progress = 50;
        
            this.dialogLoaderVisible = false,
            this.$emit('startPagination');
            this.paginationOfElements();
          });
    },

    paginationOfElements(){
      this.progress = 100;
      this.$refs.html2Pdf.generatePdf()
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#mecanismoFile").value = "";
          this.$refs.mecanismoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#mecanismoFile").value = "";
          this.$refs.mecanismoFile.reset();
          return false;
        }

        this.documentoMecanismo = e;
      }
    },

    async mostrarHistorialNegociacion(id){
      this.datosHistorial = [];
      //let datos = [];
      this.skeletonHistorial[id] = true;

      await this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Negociacion/historial/${id}`})
          .then(res => {
            if (res.status === 200) {
                this.datosHistorial = res.data;
                //this.datosHistorial = datos.replaceAll('\n', '<br/>');
                //this.datosHistorial = datos.map(str => str.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
            } else {
              this.$refs.snackalert.SnackbarShow( "warning","Alerta", res.message);
            }
            this.skeletonHistorial[id] = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow( "warning","Alerta",`Ha ocurrido un error al obtener el historial!`);
            this.skeletonHistorial[id] = false;
              this.datosHistorial = [];
          });
    },


    //!Descargar el archivo de respaldo
   //Descargar el archivo
      descargarArchivo(path){
        DownloadFile.download(path);
      },

      addBr(str) {
      const result = str.split("\n").join("<BR />")
      return result;
    }
  },
  created() {
    this.obtenerModalidades();
    this.obtenerItems();
    this.obtenerNegociacionesRegistradas();
    this.obtenerPaises();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Negociaciones" },
      { title: "Directa" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Institución",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Título",
          align: "start",
          sortable: true,
          value: "titulo"
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "descripcion"
        },
        
        {
          text: "Modalidad Ejecución",
          align: "start",
          sortable: true,
          value: "modalidad"
        },
        /*{
          text: "Mecanismo",
          align: "start",
          sortable: true,
          value: "mecanismo"
        },*/
        {
          text: "Fecha Aprobación",
          align: "start",
          sortable: true,
          value: "fechaAprobacion"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "actiones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

     headersActores() {
      return [
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "Rol"
        },
        {
          text: "País",
          align: "start",
          sortable: true,
          value: "pais"
        },
        {
          text: "Agencia Bilateral",
          align: "start",
          sortable: true,
          value: "agencia"
        },
        {
          text: "Agencia Multilateral",
          align: "start",
          sortable: true,
          value: "nombreFuenteMultilateral"
        },
       /* {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }*/
      ];
    },
  },

  watch: {
		progress (val) {
			this.$emit('progress', val)
		},
  }
};
</script>
